export default [
  {
    title: 'dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    resource: 'dashboard',
    action: 'view',
    path: '/',
  },
  {
    header: 'account_mgmt',
    icon: 'UsersIcon',
    children: [
      {
        title: 'account_overview',
        route: 'account-management.account-overview',
        resource: 'account_overview',
        action: 'view',
        path: '/account-management',
      },
      {
        title: 'partner_info',
        route: 'account-management.partner-info',
        resource: 'partner_info',
        action: 'view',
        path: '/account-management/partner-info',
      },
    ],
  },
  {
    header: 'report',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'game_history',
        route: 'report.game-history',
        resource: 'report',
        action: 'game_history',
        path: '/report/game-history',
      },
      {
        title: 'bet_transactions',
        route: 'report.bet-transactions',
        resource: 'report',
        action: 'bet_transactions',
        path: '/report/bet-transactions',
      },
      {
        title: 'transactions',
        route: 'report.transactions',
        resource: 'report',
        action: 'game_transactions',
        path: '/report/transactions',
      },
      {
        title: 'win_lose',
        route: 'report.win-lose',
        resource: 'report',
        action: 'win_lose',
        path: '/report/win-lose',
      },
      {
        title: 'jackpot',
        route: 'report.jackpot',
        resource: 'report',
        action: 'jackpot',
        path: '/report/jackpot',
      },
      {
        title: 'fund_transactions',
        route: 'report.fund_transactions',
        resource: 'report',
        action: 'transactions',
        path: '/report/fund-transactions',
      },
      {
        title: 'balance',
        route: 'report.balance',
        resource: 'report',
        action: 'balance',
        path: '/report/balance',
      },
      {
        title: 'online_users',
        route: 'report.online_users',
        resource: 'report',
        action: 'online_users',
        path: '/report/online-users',
      },
    ],
  },
  {
    header: 'audit',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'banned_list',
        route: 'audit.banned_list',
        resource: 'audit',
        action: 'banned_list',
        path: '/audit/banned-list',
      },
      {
        title: 'ban_history_list',
        route: 'audit.ban_history_list',
        resource: 'audit',
        action: 'ban_history_list',
        path: '/audit/ban-history-list',
      },
      {
        title: 'unsettled',
        route: 'audit.unsettled',
        resource: 'audit',
        action: 'view',
        path: '/audit/unsettled',
      },
    ],
  },
  {
    header: 'setting',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'log',
        route: 'setting.log',
        resource: 'setting',
        action: 'log',
        path: '/setting/log',
      },
      {
        title: 'seamless_log',
        route: 'setting.seamless_log',
        resource: 'setting',
        action: 'seamless_log',
        path: '/setting/seamless-log',
      },
      {
        title: 'avatar_activities',
        route: 'setting.avatar_activities',
        resource: 'setting',
        action: 'avatar_activities',
        path: '/setting/avatar-activities',
      },
      {
        title: 'change_password',
        route: 'setting.change-password',
        resource: 'setting',
        action: 'change_password',
        path: '/setting/change-password',
      },
      {
        title: 'table_limit',
        route: 'setting.table_limit',
        resource: 'setting',
        action: 'table_limit',
        path: '/setting/table-limit',
      },
    ],
  },
]
